import request from '@/axios/index'
export function newProject(data:any) {
  return request({
    headers: {'Content-Type': 'application/json;charset=UTF-8'},
    url: '/business/newProject',
    method: 'post',
    data: data
  })
}
// 获取产品组列表
export function queryProjectList(data:any) {
  return request({
    url: '/business/queryProjectList',
    method: 'post',
    params: data
  })
}

// 获取产品组
export function getProject(data:any) {
  return request({
    url: '/business/getProject',
    method: 'post',
    params: data,
    maxBodyLength:100000000,
    maxContentLength: 100000000
  })
}
// 修改产品组
export function updateProject(data:any) {
  return request({
    url: '/business/updateProject',
    method: 'post',
    data: data
  })
}

//保存
export function saveProjectContent(data:any) {
  return request({
    url: '/business/saveProjectContent',
    method: 'post',
    data: data
  })
}

export function getProjectByLine(data:any) {
  return request({
    url: '/business/getProjectByLine',
    method: 'post',
    data: data
  })
}
export function saveTemplateThumb(data:any) {
  return request({
    url: '/business/saveTemplateThumb',
    method: 'post',
    data: data
  })
}
export function getTemplateThumbByLine(data:any) {
  return request({
    url: '/business/getTemplateThumbByLine',
    method: 'post',
    data: data
  })
}

// 获取手提单3d展示文件

export function getBoxConfigJson(data:any) {
  return request({
    url: '/business/getBoxConfigJson',
    method: 'post',
    params: data
  })
}


// 更改产品组公共标记
export function setProjectPublic(data:any) {
  return request({
    url: '/business/setProjectPublic',
    method: 'post',
    params: data
  })
}