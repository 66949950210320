
// plupload参数文档：http://www.phpin.net/tools/plupload/
import plupload from 'plupload'
import {ref, defineComponent, reactive, toRefs, onMounted, watch, nextTick} from 'vue'
import {message} from 'ant-design-vue'
import _default from 'ant-design-vue/es/color-picker'
const columns = [
  {
    key: '1',
    title: '文件名',
    dataIndex: 'name',
    width:'100',
  },
  {
    key: '2',
    title: '状态',
    dataIndex: 'status',
    slots: { customRender: 'status' },
    width:'100',
  },
  {
    key: '3',
    title:'操作',
    slots: { customRender: 'action' },
  }
]
interface StateType{
  fileRawList:any[];
  uploading:boolean;
  startTitle:string;
  countNum:number;
}
export default defineComponent({
  name: 'Plupload',
  props: {
    // 文件上传类型限制
    acceptFiles: {
      type: String,
      default: '.png,.jpg,.tif,.jpeg'
    },
    bizType: {
      type: String,
      default: ''
    },
    fileList: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 1
    },
    upUrl: {
      type: String,
      default: 'apis/upload/uploadPicMaterial'
    },
    upType: {
      type: Number || String,
      default: 1
    },
    description: {
      type: String,
      default: ''
    },
    fileKind: {
      required: false,
      type: [String, Number],
      default: 0
    },
    orderCode: {
      required: false,
      type: String,
      default: ''
    },
    idOrder: {
      required: false,
      type: [String, Number],
      default: 0
    }
  },
  setup(props,context){
    const state = reactive<StateType>({
      fileRawList:[],
      uploading:false,
      startTitle: '开始上传',
      countNum: 1
    })

    // let fileRawList = reactive<any[]>([])
    let up = ref<any>({})
    // let uploading = ref(false)

    onMounted(()=>{
      pluploadInit()
    })
    watch(up,(newUp,oldUp)=>{
      state.fileRawList = props.fileList.concat(newUp.files)
    })
    const pluploadInit = ()=>{
      let uploader = new plupload.Uploader({
        browse_button: 'browseButton',
        url: props.upUrl,
        chunk_size: '2MB',
        // headers: that.headers,
        multipart: true, // 为true时将以multipart/form-data
        max_retries: 0, // 当发生plupload.HTTP_ERROR错误时的重试次数，为0时表示不重试
        multi_selection: false, // 是否可以在文件浏览对话框中选择多个文件
        unique_names: true,
        filters: {
          mime_types: [
            {title: "Image files",extensions: props.acceptFiles.replace(/\./g, '')}
          ], // { extensions: that.acceptFiles.replace(/\./g, '') }
          prevent_duplicates: true, // 不允许选取重复文件
          max_file_size: '10mb' // 最大只能上传400kb的文件
        },
        init: {
          BeforeUpload(up, file) {
            // 上传时的附加参数，以键/值对的形式传入
              up.setOption('multipart_params', {
                // name=保存文件名
                // srcfilename=原文件名
                // fileKind=0
                // fileDescription=文件描述
                'srcfilename': file.name,
              })
          },
          UploadProgress(up, file) {
            // console.log('上传')
            // context.emit('onUp', file.percent)
          },
          FileFiltered(up, files) {
            // console.log('FileFiltered', up, files)
          },
          FilesAdded(up, files) {
            // console.log(files)
            state.fileRawList.push(...files)
            if (state.fileRawList.length > props.limit) {
              deleteFile(state.fileRawList[0])
            }
            if (props.upType == 2) {
              countdown()
            }
          },
          FilesRemoved(up, files) {
            state.uploading = false
          },
          FileUploaded(up, files, info) {
            // console.log(files)
            if (info.status === 200) {
              // message.success('上传成功')
              up.refresh()
              // fileRawList.value = []
              context.emit('onChange')
            }
          },
          UploadComplete(up, files) {
            state.uploading = false
          },
          Error(up, args) {
            message.error(args.message)
            state.uploading = false
          }
        }
      })
      uploader.init()
      up.value = uploader
    }

     const deleteFile = (row:any)=>{
       state.fileRawList.splice(state.fileRawList.indexOf(row), 1)
       let file =up.value.getFile(row.id)
       up.value.removeFile(file)
     }
     const uploadStart = ()=>{
       state.uploading = true
       up.value.start()
     }
     const countdown =()=>{
       state.countNum--
       // console.log(that.countNum);
       state.startTitle = state.countNum + '秒'
       if (state.countNum > 0) {
         setTimeout(() => {
           countdown()
         }, 1000)
       } else {
         state.startTitle = '开始上传'
         uploadStart()
       }
     }

    return{
      // ...toRefs(fileRawList),
      ...toRefs(state),
      columns,
      uploadStart,
      deleteFile
    }

  }
})
